.layout {
    height: 100%;
    .sidebar {
        background: #FFF;
        height: 100%;
        overflow: auto;
    }

    .content {
        background: #f2f3f5;
        overflow: auto;
    }
    .addon {
        padding: 12px 24px 0 24px;
    }
}