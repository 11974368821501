.login-form-container {
    background-color: #EFEFEF;
    min-height: calc(100vh);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form {
        min-width: 320px;
    }

    header {
        text-align: center;
    }
}