.layout {
  .header {
    background: #fff;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .content {
    height: calc(100vh - 56px);
  }
}
