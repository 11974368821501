.navbar {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%);
  padding-inline: 12px;
  -webkit-user-select: none;
  -webkit-app-region: drag;

  .section {
    display: flex;
    align-items: center;
    &:last-child {
      text-align: right;
    }
  }

  &[data-env='electron'] {
    margin-top: 14px;
    height: 42px;
    line-height: 42px;
  }

  .sub {
    text-align: right;
    display: block;
    width: 100%;

    .user {
      text-align: left;
      display: flex;
      flex-direction: column;
      span {
        line-height: 1.2;
      }
    }
  }
}
